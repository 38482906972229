<template>
  <CCard class="mt-3">
    <CCardBody
      border-color="greyborder"
      style="border-radius: 15px"
      class="text-center"
    >
      <img
        src="../../../public/img/success.png"
        class="img-fluid"
        alt=""
        width="40%"
      />
      <h4 class="mt-4 font-weight-bold">สั่งซื้อสินค้าสำเร็จ</h4>
      <h6 class="text-description">{{ receiptNumber }}</h6>

      <table
        style="width: 100%; border-bottom: 1px solid #e5e5e5"
        class="table text-left small mt-3"
      >
        <tbody>
          <tr v-for="list in receiptItems" :key="list.objectId">
            <td style="width: 20%">
              <img
                style="border-radius: 3px"
                :src="list.remoteImagePath"
                alt=""
                class="img-fluid"
              />
            </td>
            <td>
              {{ list.productSKU.SKUName }}
              <br />
              <small class="text-description">
                {{ list.quantitySKU }} x {{ list.productSKU.SKUPrice }}
              </small>
            </td>
            <td class="text-right">฿{{ list.totalAmount }}</td>
          </tr>
        </tbody>
      </table>
      <table style="width: 100%" class="text-left small">
        <tbody>
          <tr>
            <td style="padding-left: 5px">รวม</td>
            <td class="text-right">฿ {{ amount }}</td>
          </tr>
          <tr>
            <td style="padding-left: 5px">ส่วนลด</td>
            <td class="text-right">-฿0.00</td>
          </tr>
          <tr>
            <td></td>
            <td class="text-right">
              <b> ฿ {{ amount }}</b>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />

      <table style="width: 100%" class="table text-left">
        <tbody class="small">
          <tr>
            <td style="border-color: #fff">
              <b> รายละเอียดคำสั่งซื้อ </b>
            </td>
            <td style="border-color: #fff"></td>
          </tr>
          <tr>
            <td>หมายเลขคำสั่งซื้อ</td>
            <td class="text-right">{{ receiptNumber }}</td>
          </tr>
          <tr>
            <td>รายการรับชำระเลขที่</td>
            <td class="text-right">{{ transactionId }}</td>
          </tr>
          <tr>
            <td>เวลาสั่งซื้อ</td>
            <td class="text-right">{{ createdAt }}</td>
          </tr>
          <tr>
            <td>วิธีการชำระ</td>
            <td class="text-right">{{ sourceType }}</td>
          </tr>
          <tr>
            <td>ยอดชำระ</td>
            <td class="text-right">{{ amount }} THB</td>
          </tr>
          <tr>
            <td>สถานะการชำระ</td>
            <td>
              <h6 class="small font-weight-bold text-right text-success">
                {{ statusMessage }}
              </h6>
            </td>
          </tr>
        </tbody>
      </table>

      <button class="btn btn-dark btn-block mt-1" @click="goToShop()">
        กลับไปยังร้านค้า
      </button>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex'
import payment from '@/services/payment'
import poscrm from '@/services/poscrm'
import util from '@/util/util'
import moment from 'moment'

export default {
  data() {
    return {
      enquiry: null,
      order: {},
      receiptItems: [],
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopLineObjectId
      )
    },
    objectId() {
      return this.$route.query.objectId
    },
    transactionId() {
      return this.$route.query.transactionId
    },
    statusMessage() {
      if (this.order !== null) {
        if (this.order.Payment.paymentStatus === 'A') {
          return 'ชำระเรียบร้อย'
        }
      }
    },
    amount() {
      if (this.order !== null) {
        let payment = this.order.Payment
        let amountData = payment.paid
        return util.convertCurrency(amountData)
      }
    },
    createdAt() {
      if (this.order !== null) {
        return moment(this.order.created_at).format('DD/MM/YYYY HH:mm A')
      }
    },
    receiptNumber() {
      if (this.order) {
        return this.order.receiptNumber
      }
    },
    sourceType() {
      if (this.order) {
        return this.order.Payment.paymentItems[0].customPaymentName
      }
    },
  },
  mounted() {
    //this.getEnquiry()
    this.getOrderDetail()
  },
  methods: {
    goToShop() {
      sessionStorage.clear()
      this.$router.push('/pickup/menulists')
    },
    getOrderDetail() {
      let params = {
        shopObjectId: this.shopLineObjectId,
        objectId: this.objectId,
      }

      poscrm
        .get('/api/v1.1/' + this.uid + '/Receipt/order/data', { params })
        .then((res) => {
          this.order = res.data.data
          this.receiptItems = this.order.receiptItems

          console.log(this.order)
        })
    },
    getEnquiry() {
      payment({
        url:
          '/mpay/payment/v1.0/' + this.uid + '/enquiry/' + this.transactionId,
        method: 'get',
      }).then((res) => {
        this.enquiry = res.data.data
        console.log(this.enquiry)
      })
    },
  },
}
</script>
